// @ts-check

import {ModelsEnum} from "@/common/enum/index"
//@ts-ignore
import { $get, $post, $dataPost, $put } from '../../http/axios'
/**
 * @desc 对象过滤回调函数 
 * @param {*} 对象的值
 * @param {string} 对象的键
 */
 type FilterCallbackFn = (value:any,key:string)=>Boolean 


 /**
  * @file ListQuerytor.js
  * @desc 列表查询器 基于 QueryTable.js 重构
  * @auth  HUANGJI
  */
type ListQuerytorConstructorParams = {
  model?:ModelsEnum,
  condition?: Object,
  map?:{
    [key:string]:<T = any>(value:T)=>any
  },
  pager?: API.Pager,
  /**
   * 可传入在外部定义好的请求方法 或者 接口地址  
   */
  api?:((...arg:any[])=>Promise<any>) | string
}


export default class ListQuerytor{
   /** @desc 分页器  */
   public pager:API.Pager ={size:10}
   public curPage:number = 1
    /** @desc 查询条件  */
   public condition:QueryCondition
   /** @desc 查询模块名  */
   public model?:ModelsEnum
   /** @desc 生成或传入的api  */
   private api:ListQuerytorConstructorParams["api"]
   public loading:boolean = false
 
   public table:Array<any> = []
   public maps:ListQuerytorConstructorParams['map'] = {}

   /**
   * @constructor
   * @param {ModulesEnum} module 查询模块名
   * @param {ListQuerytorConstructorParams["api"]} api 接口
   * @param {Object} [condition = {}]  查询条件
   * @param {Pager} [pager = {size:10,page:1}] 分页器
  */
   constructor({model,api,condition={},map={} ,pager = {size:10,page:1}}:ListQuerytorConstructorParams = {}){
     this.condition = new QueryCondition(condition);
     this.maps = map
     this.pager = Object.defineProperties(pager,{
      page:{
        enumerable:true,
        set:val=>{
          this.curPage = val
          this.handlePagerChange(val)
        } ,
        get:()=>this.curPage || 1
      }
    
    });
     this.model = model;
     if(api){
       this.api = api
     }else{
       this.api = `/${model}/list`
     }
   }

   handlePagerChange(page:number){
     console.log("[ListQuerytor]页码更新触发",page)
     this.query();
   }

   setCondition(conditions:Object = {}){
     Object.entries(conditions).map(([key,value])=>{
       this.condition.set(key,value)
     })
     return this;
   }

   config({api,size}:(ListQuerytorConstructorParams & {size?:number} ) =  {}):ListQuerytor{
     api && ( this.api = api);
     size && (this.pager.size = size)
     return this;
   }
    //  public list:Array<any> = []
   get list() { 
     const {page,size} = this.pager;
     return this.table // (this.table || []).slice(((page || 1) - 1)* size,((page || 1) - 1)* size + size)
   }

   async query(){
    // this.condition.set("page",this.pager.page)
    // this.condition.set("size",this.pager.size)
    this.loading = true;
    let response;
    /**
     * 请求器
     */
    let requester =  this.api instanceof Function ? this.api : 
    ( typeof this.api === 'string' ? (...arg:any)=>$post(this.api,...arg) : undefined )
    if(requester){
      let {message,success,rows,result,total}  =  response = await requester({...this.pager,...this.condition.parse()},)
      if(success){
        this.table = (rows || result).map((item:any)=>{
           for(let key in item){
              if(this.maps){
                if(this.maps[key] && this.maps[key] instanceof Function){
                  item[key] = this.maps[key](item[key])
                }
              }
              
           }
           return item;
        });
        this.table = rows || result;
        this.pager.total  = total ;
      }
      this.loading  = false
    }
    console.log("ListQuery请求响应",response)
    //  this.paginate(this.moduleName, this.condition)
    //   .then(res => {
    //     if (res) {
    //       this.table = res.rows || res.result
    //       this.count = res.total
    //       if (this.count > 0 && this.table.length === 0) {
    //         // 当前页超出最大页码，当前页减1重取数据，一般在删除数据后出现
    //         this.page -= 1
    //         return this.search()
    //       }
    //     }
    //     this.loading = false
    //   }).catch(_ => {
    //     this.loading = false
    //   })

   }
}

class QueryCondition{
  public condition:API.Form ={
    // page:1,
    // size:10
  }
 /**
  * @param {Object} condition query condition 
  */
 constructor(condition:API.Form){
   this.condition =condition;
   Object.assign(this, QueryCondition.filter(condition,(value)=>!value&& value!==''))
 }
 set(key:string,value:any){
   this.condition[key] =value
 }
 parse(){
   return this.condition
 }

 /**
  * 对象过滤
  * @param {Object} target  fillter target object
  * @param {Function | Array} filters   
  * @returns {Object}
  */
  public static filter(target:API.Form,filters: FilterCallbackFn | Array<string>):Object{
   const reduceObject = (result:StringKeyObject,key:string)=>({...result,[`${key}`]:target[key]})
   if(filters instanceof Array){
     return Object.keys(target).filter(key=> filters.includes(key)).reduce(reduceObject,{})
   }
   if(filters instanceof Function){
     return  Object.keys(target).filter(key=> filters(target[key],key)).reduce(reduceObject,{})
   }
   return target
 }
 /**
  * @desc 将查询条件{a:1，b:2}格式化为“a=1&b=2”字符串
  * @param {boolean} [prefix=false] 是否在结果字符串之前添加前缀
  * @returns {string}
  */
  toQueryString(prefix:Boolean){
   return Object.keys(this.condition).reduce((query,key:any,index,keys)=> 
    `${query}${key}=${this.condition[key]}${index < keys.length - 1 ? '&' : ''}`,
    prefix ? '?' : '')
 }
 


}

import Common from './common'
// import { Message } from 'element-ui'

/**
 * @class QueryTable
 * @template {BaseQueryTableParams} T
 * @desc 分页列表查询
 */
export class QueryTable {
  /** @type {string} @desc 模块名 */
  moduleName = null;
  /** @type {boolaen} @desc 是否加载中 */
  loading = false;
  table = [];
  count = null;
  pageSize = 5;
  /** @type {number} */
  page = 1;
  /**  @type {T}  */
  condition = {};
  initCondition = {};
  selectedRow = {};
  paginate = Common.paginate
 /**
  * @param {string} moduleName 
  * @param {T} condition 
  * @param {*} paginate 
  */
  constructor(moduleName, condition = {}, paginate = false) {
    this.moduleName = moduleName
    this.condition = condition
    this.initCondition = JSON.parse(JSON.stringify(condition))
    this.paginate = paginate ? paginate : Common.paginate
  }

  /**
   * 搜索，当前页
   * @returns {Promise.<TResult>|*}
   */
  search() {
    this.condition.page = this.page
    this.condition.size = this.pageSize
    this.loading = true
    return this.paginate(this.moduleName, this.condition)
      .then(res => {
        if (res) {
          this.table = res.rows || res.result
          this.count = res.total
          if (this.count > 0 && this.table.length === 0) {
            // 当前页超出最大页码，当前页减1重取数据，一般在删除数据后出现
            this.page -= 1
            return this.search()
          }
        }
        this.loading = false
      }).catch(_ => {
        this.loading = false
      })
  }

  /**
   *  查询，第一页
   * @returns {Promise.<TResult>|*}
   */
  query() {
    // 搜索的话，要从第一页显示
    this.page = 1
    return this.search()
  }

  /**
   *  重置搜索条件
   */
  reset() {
    this.condition = JSON.parse(JSON.stringify(this.initCondition))
  }

}

// import Storage from 'good-storage'
import {$get, $post} from '@/http/axios'
// import store from '@/store'
// import qs from 'qs'
import Config from '@/http/config.js'


/**
 * @typedef {import('./typedef/index').BaseQueryTableParams} BaseQueryTableParams
 */

/**
 * 对应后台的分页方法
 * 
 * @template { BaseQueryTableParams } T
 * @param {string} moduleName 模块名
 * @param {T} params 查询条件
 * @returns {AxiosPromise}
 */
function paginate(moduleName, params = {}) {
  return $get('/wztc/' + moduleName + '/list', params)
}
// /**
//  * 查询返回所有数据（不分页）
//  * @param moduleName 模块名
//  * @param condition 查询条件
//  * @returns {AxiosPromise}
//  */
// function listAll(moduleName, condition = {}) {
//   let url = moduleName + '/listAll'
//   return request({
//     url: url,
//     method: Config.method,
//     data: condition
//   })
// }

// /**
//  * 新增或更新方法
//  * @param moduleName
//  * @param data 中有id值为新增
//  * @returns {AxiosPromise}
//  */
// function save(moduleName, data = {}) {
//   let url = moduleName + '/save' + Config.apiFileType
//   return request({
//     url: url,
//     method: Config.method,
//     data: data
//   })
// }

// /**
//  * 新增或更新方法
//  * @param moduleName
//  * @param data 中有id值为新增
//  * @returns {AxiosPromise}
//  */
// export function saveOrUpdate(moduleName, data = {}) {
//   let url = moduleName + '/saveOrUpdate' + Config.apiFileType
//   return request({
//     url: url,
//     method: Config.method,
//     data: data
//   })
// }



// /**
//  * 向后端接口获取已登录用户信息
//  * @param redirect Boolean 未登录时是否跳转到登录页
//  * @returns {AxiosPromise}
//  */
// function getLoggedUserInfo (redirect = true) {
//   let url = 'systemInfo/getLoggedUserInfo' + Config.apiFileType
//   return request({
//     url: url,
//     method: Config.method
//   }).then((res) => {
//     if (res.code === 2000) {
//       if (res.result && res.result.length) {
//         setUserInfo(res.result[0])
//         store.commit('updateValue', { userStore: res.result[0] })
//       } else {
//         removeUserInfo()
//         store.commit('updateValue', { userStore: null })
//       }
//     } else {
//       removeUserInfo()
//       store.commit('updateValue', { userStore: null })
//       if (res.code === 5105 && redirect) {
//         window.location.href = res.result + '?redirect=' + encodeURIComponent(window.location.href)
//       }
//     }
//   })
// }

// /**
//  *获取localStorage中用户信息
//  * @param key 字段名称，为空时返回所有用户信息
//  */
// function getUserInfo (key = null) {
//   let userInfo = Storage.get(Config.userInfoKey)
//   if (key) {
//     return userInfo.hasOwnProperty(key) ? userInfo[key] : null
//   }
//   if (store.state.userStore == null) {
//     store.commit('updateValue', { userStore: userInfo })
//   }
//   return userInfo
// }

// /**
//  *将用户信息保存到localStorage中
//  * @param user 用户信息Object
//  */
// function setUserInfo (user) {
//   Storage.set(Config.userInfoKey, user)
//   return user
// }

// /**
//  *删除localStorage中的用户信息
//  */
// function removeUserInfo () {
//   return Storage.remove(Config.userInfoKey)
// }

// /**
//  *获取sessionStorage中token
//  */
// function getToken () {
//   return Storage.session.get(Config.tokenKey)
// }


export default {
  paginate: paginate,
  // list: listAll,
  // save: save,
  // saveOrUpdate: saveOrUpdate,
}
